@use '../../../../variables';

.main_container {
    margin-left: 2%;

    .orders_title {
        font-size: larger;
        font-size: 28px;
        font-weight: bold;
        text-transform: uppercase;
        color: variables.$app_green_color;
        margin-bottom: 2%;
    }

    a {
        text-decoration: none;
        color: variables.$basic_black_color;
        font-size: 12px;

        &:hover {
            cursor: pointer;
            color: variables.$app_light_green_color;
        }
    }

    .order_column,
    .address_column,
    .date_column,
    .summary_column,
    .status_column {
        text-align: center;
    }

    .no_orders_warning {
        width: 100%;
        height: 142px;
        background-color: variables.$app_green_color;
        border-radius: 10px;

        .warning_content {
            display: flex;
            color: white;
            padding-left: 10px;
            padding-top: 10px;

            .warning_icon {
                margin-right: 10px;
            }

        }

        .go_shopping_button {
            background-color: variables.$app_grey_color;
            width: 240px;
            height: 50px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: variables.$font-family;
            cursor: pointer;
            margin-top: 1%;
            margin-left: 10px;
            text-transform: uppercase;
            font-weight: bold;
            color: variables.$basic_black_color;
            font-size: 12px;
            border: none;
        }
    }
}