@use '../../../variables';

.container {
    background: variables.$app_green_color;
    color: variables.$basic_white_color;
    display: grid;
    font-family: variables.$font-family;

    .decore_wrapper {
        width: 100%;
        height: 100px;
        background: url('../../../assets/img/decor.jpg') no-repeat;
        background-position: left;
        background-size: cover;
    }

    .footer_container {
        width: 100%;
        display: flex;
        justify-content: center;


        .columns {
            width: 70%;
            display: flex;
            justify-content: center;

            .footer_section {
                width: 20%;
                margin: 2%;
                display: flex;
                flex-direction: column;

                p,
                a {
                    font-size: 15px;
                }

                a {
                    text-decoration: none;
                    color: variables.$basic_white_color;
                }

                img {
                    filter: variables.$convert_to_white_color;
                    margin-left: 5px;
                }
            }
        }
    }

    .newsletter {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1300px;
        margin: 100px auto 60px auto;

        .left_side {
            min-width: 350px;
            display: flex;
            align-items: center;
            width: 49%;

            img {
                height: 55px;
                align-self: flex-end;
                filter: variables.$convert_to_white_color;
                margin-left: -10px;
            }

            .description_container {
                .top {
                    text-transform: uppercase;
                    font-family: variables.$second_font_family;
                    font-size: 17px;
                }

                .bottom {
                    font-size: 12px;
                }
            }
        }

        .right_side {
            min-width: 350px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.3em;
            height: 3.5rem;
            background-color: variables.$basic_white_color;
            width: 51%;
            border-radius: 4px;

            input {
                outline: none;
                border: none;
                font-family: variables.$font-family;
                font-weight: bold;
                font-size: 13px;
                width: 70%;
                margin-left: 2%;
            }

            .button {
                border-radius: 4px;
                background-color: variables.$app_light_green_color;
                width: 150px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    .logo_wrapper {
        width: 100%;
        max-width: 1300px;
        margin: 0 auto 50px auto;
    }


    .line {
        height: 2px;
        background-color: gray;
        opacity: 0.3;
        margin-top: 75px;
        margin-bottom: 30px;
    }

    .copyright {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        max-width: 1300px;
        margin: 0 auto 75px auto;

        .left {
            min-width: 130px;

            img {
                width: 85px;
                height: auto;
            }
        }

        .right {
            min-width: 360px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;


            .UEInfo {
                text-transform: uppercase;
                font-family: variables.$second_font_family;
                font-size: 14px;
                margin-right: 15px;
            }

            img {
                background-color: white;
                border-radius: 4px;
                width: 250px;
                height: auto;
            }

            p {
                margin-bottom: 0;
            }

            .copyright_label {
                font-size: 14px;
                margin-left: 15px;
                font-weight: 700;
            }
        }
    }
}

@media screen and (max-width: 1300px) {

    .newsletter,
    .logo_wrapper,
    .columns,
    .copyright {
        padding: 0 20px;
    }

    .footer_container {
        .columns {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1115px) {
    .columns {
        .second_column {
            .phone {
                .number {
                    .week_info {
                        width: 150px !important;
                    }
                }
            }
        }
    }

    .footer_container {
        width: 100%;

        .columns {
            width: 90%;
            flex-wrap: wrap;

            .footer_section {
                min-width: 40%;
            }
        }
    }
}

@media screen and (max-width: 894px) {
    .footer_container {
        .newsletter {
            margin: 50px auto;

            .left_side {
                width: 100%;
            }

            .right_side {
                width: 100%;
                margin-top: 25px;
            }
        }

        .logo_wrapper {
            text-align: center;
            margin-bottom: 20px;
        }

        .columns {

            .first_column {
                width: 100%;
                align-items: center;
                margin-top: 0;

                .address {
                    margin-bottom: 10px;
                    text-align: center;
                }

                .follow_us {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            .second_column {
                width: 100%;
                margin-top: 20px;
                text-align: center;

                .phone {
                    margin-top: 25px;
                    justify-content: center;
                    gap: 0;
                    position: relative;

                    img {
                        position: absolute;
                        top: 0;
                        left: calc(50% - 90px);
                    }

                    .number {
                        .week_info {
                            width: 260px !important;
                        }
                    }
                }

                .email {
                    justify-content: center;
                    margin-top: 0;
                }
            }

            .third_column,
            .fourth_column,
            .fifth_column {
                display: none;
            }
        }

        .line {
            margin-top: 30px;
        }

        .copyright {
            flex-direction: column;
            margin-bottom: 25px;

            .left {
                min-width: auto;
                margin-bottom: 15px;
            }

            .right {
                flex-direction: column;

                .UEInfo {
                    margin-right: 0;
                    margin-bottom: 5px;
                }

                .copyright_label {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }
    }

    .footer_container {
        .columns {
            width: 90%;
            padding: none;

            .footer_section {
                display: flex;
                min-width: 100%;
            }


        }
    }


}