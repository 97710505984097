@use '../../../../variables';

input[type=checkbox] {
    accent-color: variables.$app_light_green_color;
    width: 15px;
    height: 15px;
}

.checkbox_row,
.input_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    height: fit-content;

    .data_name {
        font-size: medium;
        font-weight: bold;
        color: variables.$app_light_green_color;
        font-family: variables.$second_font_family;
        margin: 0;
    }

    .data_value {
        font-size: medium;
        font-weight: bold;
        margin: 0;
    }
}

.input_row {
    .data_name {
        width: 140px;
    }

    margin-bottom: 10px;
}

.btn_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit_button {
    text-align: center;
    text-transform: uppercase;
    height: 48px;
    line-height: 48px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    border: none;
    border-radius: 5px;
    background-color: #68902f;
    width: 150px;
}

.error {
    width: 100%;
    color: variables.$basic_red_color;
    text-align: right;
    font-size: 9pt;
}