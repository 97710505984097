@use '../../variables';

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;

    .left_side {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        height: 100%;

        .form_container {
            width: 50%;
            height: 50%;
            border: solid 1px variables.$border_color;
            border-radius: 12px;
            padding-left: 5%;
            padding-right: 5%;
            padding-top: 5%;
            margin-top: 5%;
        }
    }

    .right_side {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .actions_container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 70%;
            height: 30%;
            margin-top: 5%;
            padding-top: 5%;

            .header {
                font-size: 17px;
                font-weight: bold;
            }

            .buttons_container {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 50%;
                justify-content: space-between;
                align-items: center;
                margin-top: 5%;

                .actionButton {
                    height: 40%;
                    width: 50%;
                }

                .firstButton {
                    background-color: variables.$basic_white_color;
                    border-radius: 6px;
                    font-weight: bold;
                    border: solid 2px;
                }

                .secondButton {
                    background-color: variables.$basic_black_color;
                    border-radius: 6px;
                    color: variables.$basic_white_color;
                    font-weight: bold;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .container {
        flex-direction: column;

        .left_side,
        .right_side {
            width: 100%;
            height: 50%;
            justify-content: center;
            align-items: center;

            .form_container {
                width: 70%;
                height: auto;
                margin-top: 5%;
            }

            .actions_container {
                width: 80%;
                height: auto;
                margin-top: 5%;
                padding-top: 5%;

                .header {
                    font-size: 16px;
                }

                .buttons_container {
                    height: 70%;

                    .actionButton {
                        height: 40px;
                        width: 70%;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .container {

        .left_side,
        .right_side {

            .form_container,
            .actions_container {
                width: 90%;
                margin-top: 10%;

                .header {
                    font-size: 15px;
                }

                .buttons_container {

                    .actionButton {
                        width: 80%;
                        height: 50px;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .container {

        .left_side,
        .right_side {

            .form_container,
            .actions_container {
                width: 100%;
                margin-top: 15%;

                .header {
                    font-size: 14px;
                    text-align: center;
                }

                .buttons_container {
                    .actionButton {
                        width: 90%;
                        height: 45px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}