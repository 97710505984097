@use '../../variables';

.main_container {
    background-color: variables.$basic_white_color;
    margin-left: 50%;
    width: 28%;
    transform: translateX(-50%);
    padding-top: 2%;
    border-radius: 4;
    padding-bottom: 2%;
    border-radius: 3px;
    border: 1px solid variables.$app_intense_grey_color;
    margin-top: 2%;
    margin-bottom: 8%;
    display: flex;
    flex-direction: column;
    padding: 3%;

    .remember_reset_container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: -4%;
    }

    @media screen and (max-width: 1370px) {
        .main_container {
            width: 80%;
        }
    }

}