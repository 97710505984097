@use '../../variables';

.register_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .form_container {
        display: flex;
        flex-direction: column;
        padding: 3%;
        border: 1px solid variables.$app_intense_grey_color;
        margin-bottom: 2%;

        .navigate_buttons {
            display: flex;
            text-transform: uppercase;
            font-size: 18px;
            font-family: variables.$second_font_family;
            font-weight: bold;
            margin-bottom: 3%;
            margin-left: 1%;

            .login {
                margin-right: 5%;
                opacity: 0.5;
                color: variables.$app_intense_grey_color;
                cursor: pointer;
            }

            .register {
                text-decoration: underline;
            }
        }

        .form {
            display: flex;

            input:-webkit-autofill {
                -webkit-background-clip: text;
            }

            input,
            select {
                border: 2px solid variables.$app_intense_grey_color;
                border-radius: 3px;

            }

            input[type=radio] {
                accent-color: variables.$app_light_green_color;
                width: 15px;
                height: 15px;
            }

            .left_section {

                .basic_data {

                    .section_title {
                        margin-left: 1.5%;
                        font-size: 25px;
                        text-transform: uppercase;
                        color: variables.$app_light_green_color;
                    }
                }

                .address_data {
                    margin-top: 8%;

                    .section_title {
                        margin-left: 1.5%;
                        font-size: 25px;
                        text-transform: uppercase;
                        color: variables.$app_light_green_color;
                    }
                }
            }

            .form_row {
                display: flex;
                flex-direction: column;
                width: 28vw;
                margin: 1vh;

                .field_name {
                    width: 7vw;
                    margin-bottom: 1%;
                    font-size: 13px;
                    font-family: variables.$second_font_family;
                }

                .normal_field {
                    width: 20vw;
                    padding: 2%;
                }

                .country_dropdown {
                    width: calc(20vw);
                    padding: 2%;

                    option {
                        background-color: variables.$app_light_green_color;
                        color: variables.$basic_white_color;

                        &:hover {}
                    }
                }

                .address_type_radio_group {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-top: 1%;
                    gap: 3%;

                    .control {
                        min-width: 80px;
                        display: flex;
                        gap: 6%;

                        input {}

                        label {
                            transform: translateY(-20%);
                        }
                    }
                }

                .contact_title_radio_group {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-top: 1%;

                    .control {
                        min-width: 80px;
                        display: flex;
                        gap: 6%;

                        input {}

                        label {
                            transform: translateY(-20%);
                        }
                    }
                }


            }

            .double_field_row {
                display: flex;
                width: 28vw;
                margin: 1vh;
                align-items: center;

                .form_row {
                    margin: 0;
                    width: 14vw;

                    .first_field_name {
                        width: 7vw;
                        margin-bottom: 1%;
                        font-size: 13px;
                        font-family: variables.$second_font_family;
                    }

                    .first_double_row_input {
                        width: 40%;
                        padding: 4%;
                    }

                    .second_field_name {
                        width: 7vw;
                        margin-bottom: 1%;
                        font-size: 13px;
                        font-family: variables.$second_font_family;
                    }

                    .second_double_row_input {
                        width: 42%;
                        padding: 4%;
                    }

                    .flat_field_name {
                        width: 7vw;
                        margin-bottom: 1%;
                        font-size: 13px;
                        font-family: variables.$second_font_family;
                    }

                    .equal_double_row_input {
                        width: 42%;
                        padding: 4%;
                    }
                }
            }

            .right_section {
                display: flex;
                flex-direction: column;

                .contact_data {
                    height: 60%;

                    .section_title {
                        margin-left: 1.5%;
                        font-size: 25px;
                        text-transform: uppercase;
                        color: variables.$app_light_green_color;
                    }
                }

                .button_container {
                    height: 50%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;

                    button {
                        height: 3rem;
                        width: 10rem;
                        border: none;
                        outline: none;
                        font-family: variables.$second_font_family;
                        text-transform: uppercase;
                        color: variables.$basic_white_color;
                        border-radius: 3px;
                        background-color: variables.$app_light_green_color;
                    }
                }
            }

            .errorText {
                color: variables.$basic_red_color;
                margin-left: 25px
            }
        }
    }
}

@media screen and (max-width: 1310px) {
    .register_container {

        .form_container {

            .form {
                display: flex;
                flex-direction: column;
                padding-right: 3%;

                .left_section {

                    .basic_data {
                        display: flex;
                        flex-direction: column;

                        .form_row {
                            display: flex;
                            width: 100%;
                            gap: 10%;

                            .field_name {
                                width: max-content;
                                margin-right: 10%;
                                margin-top: 0.5%;
                                margin-bottom: 0.5%;
                            }

                            .normal_field {
                                width: 100%;
                            }
                        }
                    }

                    .address_data {
                        display: flex;
                        flex-direction: column;

                        .form_row {
                            display: flex;
                            width: 100%;
                            gap: 10%;

                            .field_name {
                                width: max-content;
                            }

                            .country_dropdown {
                                width: 100%;
                            }

                            .normal_field {
                                width: 100%;
                            }
                        }

                        .double_field_row {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .form_row {
                                display: flex;
                                width: 100%;
                                gap: 5%;

                                .first_field_name {
                                    width: max-content;
                                    margin-right: 10%;
                                    margin-top: 0.5%;
                                    margin-bottom: 0.5%;
                                }

                                .flat_field_name {
                                    margin-top: 2%;
                                    width: max-content;
                                    margin-right: 10%;
                                }

                                .first_double_row_input {
                                    width: 100%;
                                    padding: 2%;
                                }

                                .equal_double_row_input {
                                    width: 100%;
                                    padding: 2%;
                                }
                            }

                            .form_row {
                                display: flex;
                                width: 100%;
                                gap: 10%;

                                .second_field_name {
                                    width: max-content;
                                    margin-right: 10%;
                                    margin-top: 2%;
                                }

                                .second_double_row_input {
                                    width: 100%;
                                    padding: 2%;
                                }

                                .equal_double_row_input {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .right_section {

                    .contact_data {

                        display: flex;
                        flex-direction: column;

                        .form_row {
                            display: flex;
                            width: 100%;
                            gap: 10%;

                            .field_name {
                                width: max-content;
                                margin-right: 10%;
                            }

                            .normal_field {
                                width: 100%;
                                margin-top: 1%;
                            }
                        }
                    }
                }
            }
        }
    }
}