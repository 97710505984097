@use '../../../variables';


.form_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 8%;

    .field_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: variables.$font-family;
        margin-bottom: 7%;

        .field_name {
            width: 25%;
            margin-bottom: 1%;
            font-size: 14px;
            font-family: variables.$second_font_family;
        }

        .field_input {
            width: 100%;
            padding: 0.5rem;
            height: 3rem;
            border: 1px solid variables.$app_intense_grey_color;
            border-radius: 3px;
            font-weight: bold;

            &:-webkit-autofill {
                -webkit-background-clip: text;
            }
        }
    }

    .remember_reset_container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: -4%;

    }

    .submit_button_container {
        align-self: flex-end;
        margin-top: 10%;
        width: 100%;
        height: 3rem;

        button {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            outline: none;
            border: none;
            background-color: variables.$app_light_green_color;
            text-transform: uppercase;
            color: variables.$basic_white_color;
            font-weight: 500;

        }
    }
}

@media screen and (max-width: 1370px) {
    .main_container {
        width: 80%;
    }
}