.main_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .header {}

    .content {
        min-height: 400px;
    }
}