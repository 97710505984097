@use '../../../variables';

.navigate_buttons {
    display: flex;
    text-transform: uppercase;
    font-size: 18px;
    font-family: variables.$second_font_family;
    font-weight: bold;

    .login {
        margin-right: 5%;
        text-decoration: underline;
    }

    .register {
        opacity: 0.5;
        color: variables.$app_intense_grey_color;
        cursor: pointer;
    }
}